import Vue from "vue";
import App from "./App.vue";
import "./assets/tailwind.css";

import "./assets/sass/pages/billing-parking/main.scss";
require("./plugins");
Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
