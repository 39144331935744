<template>
  <div class="md:grid container px-5 py-5">
    <div class="mt-5 md:mt-0 md:col-span-2">
      <BlockUI :message="msg" v-if="showLoader">
        <i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
      </BlockUI>
      <form @submit.prevent="submit" enctype="multipart/form-data">
        <div class="shadow sm:rounded-md sm:overflow-hidden">
          <div
            class="grid grid-cols-9 sm:grid-cols-11 gap-4 px-4 py-5 bg-white space-y-6 sm:p-6"
          >
            <div class="col-span-12 sm:col-span-12 lg:col-span-12">
              <label for="name" class="block text-sm font-medium text-gray-700">
                Nombre / Razon Social
              </label>
              <input
                type="text"
                v-model="invoice.name"
                v-validate="'required'"
                data-vv-as="Razón Social"
                name="name"
                id="name"
                autocomplete="given-name"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              <div class="invalid-feedback">
                {{ errors.first("name") }}
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-6">
              <label
                for="branch"
                class="block text-sm font-medium text-gray-700"
              >
                Regimen Fiscal
              </label>
              <select
                id="taxRegime"
                v-model="invoice.taxRegime"
                v-validate="'required'"
                data-vv-as="Regimen fiscal"
                name="taxRegime"
                autocomplete="taxRegime"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="" disabled>- Seleccione un Regimen Fiscal -</option>
                <option
                  v-for="data in OptionsTaxRegime"
                  :key="data.id"
                  :value="data.value"
                >
                  {{ data.value }}
                </option>
              </select>
              <div class="invalid-feedback">
                {{ errors.first("taxRegime") }}
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-6">
              <label for="rfc" class="block text-sm font-medium text-gray-700"
                >RFC
              </label>
              <input
                type="text"
                v-model="invoice.rfc"
                v-validate="'required'"
                data-vv-as="RFC"
                name="rfc"
                id="rfc"
                autocomplete="RFC"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              <div class="invalid-feedback">
                {{ errors.first("rfc") }}
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-6">
              <label
                for="email"
                class="block text-sm font-medium text-gray-700"
              >
                Correo Electronico
              </label>
              <input
                type="email"
                v-model="invoice.email"
                v-validate="'required|email'"
                data-vv-as="email"
                name="email"
                id="email"
                autocomplete="email"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              <div class="invalid-feedback">
                {{ errors.first("email") }}
              </div>
            </div>
            <div class="col-span-12 sm:col-span-3 lg:col-span-2">
              <label
                for="postal-code"
                class="block text-sm font-medium text-gray-700"
              >
                Codigo Postal
              </label>
              <input
                type="text"
                v-model="invoice.zipCode"
                name="postal-code"
                v-validate="'required|numeric|min:5|max:5'"
                data-vv-as="Codigo Postal"
                id="postal-code"
                autocomplete="postal-code"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              <div class="invalid-feedback">
                <span>{{ errors.first("postal-code") }}</span>
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-6">
              <label
                for="branch"
                class="block text-sm font-medium text-gray-700"
              >
                Sucursal
              </label>
              <select
                id="branch"
                v-model="invoice.branch"
                v-validate="'required'"
                data-vv-as="Sucursal"
                name="branch"
                autocomplete="branch"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="" disabled>- SeleccioRFCne una sucursal -</option>
                <option
                  v-for="sucursal in OptionsBranch"
                  :key="sucursal.id"
                  :value="sucursal.name"
                >
                  {{ sucursal.name }}
                </option>
              </select>
              <div class="invalid-feedback">
                {{ errors.first("branch") }}
              </div>
            </div>

            <div class="col-span-12 sm:col-span-6 lg:col-span-6">
              <label
                for="total"
                class="block text-sm font-medium text-gray-700"
              >
                # de Ticket
              </label>
              <input
                type="text"
                v-model="invoice.numberTicket"
                v-validate="'required'"
                data-vv-as="número de ticket"
                name="number-ticket"
                id="number-ticket"
                autocomplete="número de ticket"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              <div class="invalid-feedback">
                {{ errors.first("number-ticket") }}
              </div>
            </div>
            <div class="col-span-12 sm:col-span-12 lg:col-span-6">
              <label
                for="total"
                class="block text-sm font-medium text-gray-700"
              >
                Fecha de Ticket
              </label>
              <input
                type="date"
                v-model="invoice.dateTicket"
                v-validate="'required'"
                data-vv-as="fecha de ticket"
                name="date-ticket"
                id="date-ticket"
                autocomplete="fecha de ticket"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              <div class="invalid-feedback">
                {{ errors.first("date-ticket") }}
              </div>
            </div>
            <div class="col-span-12 sm:col-span-12 lg:col-span-6">
              <label
                for="total"
                class="block text-sm font-medium text-gray-700"
              >
                Total
              </label>
              <input
                type="text"
                v-model="invoice.total"
                v-validate="'required'"
                data-vv-as="Total"
                name="total"
                id="total"
                autocomplete="Total"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              <div class="invalid-feedback">
                {{ errors.first("total") }}
              </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-6">
              <label
                for="digits"
                class="block text-sm font-medium text-gray-700"
              >
                Ultimos 4 digitos de Forma de pago
              </label>
              <input
                type="text"
                v-model="invoice.digits"
                v-validate="'numeric|min:4|max:4'"
                data-vv-as="Ultimos 4 digitos de Forma de pago"
                name="digits"
                id="digits"
                autocomplete="digits"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
              <div class="invalid-feedback">
                {{ errors.first("digits") }}
              </div>
            </div>
            <div class="col-span-12 sm:col-span-12 lg:col-span-12 py-5">
              <div class="border-t border-gray-200"></div>
            </div>
            <div class="col-span-12 sm:col-span-12 lg:col-span-12">
              <label class="block text-sm font-medium text-gray-700">
                Comprobante de pago.
              </label>
              <label class="block text-sm font-medium text-gray-700">
                (Agrega uno o más recibos)
              </label>
              <p class="text-blue-600 text-xl">
                Favor de anexar constancia de situación fiscal.
              </p>
              <vue-dropzone
                ref="myVueDropzone"
                id="dropzone"
                :options="dropzoneOptions"
                :useCustomSlot="true"
                class="border-2 border-gray-300 border-dashed"
                @vdropzone-validation-failed="handleValidationFailed"
              >
                <div class="dropzone-custom-content">
                  <h3 class="dropzone-custom-content__title">
                    Arrastra y suelta para subir recibos!
                  </h3>
                  <div class="dropzone-custom-content__subtitle">
                    ...o haga clic para seleccionar un archivo de su computadora
                  </div>
                </div>
                <div v-if="validationError" class="invalid-feedback">
                  {{ validationError }}
                </div>
              </vue-dropzone>
            </div>
          </div>
          <p class="text-blue-600 text-xl text-center p-2">
            No se podrán facturar tickets que no sean dentro del mismo mes de
            emisión.
          </p>
          <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <transition name="fade">
              <p v-if="showMessage" class="text-center py-4 text-2xl">
                🏆 Información envíada.
              </p>
            </transition>
            <button
              type="submit"
              class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Envíar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  name: "FormComponent",
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      paymentTypes: [],
      validationError: null,
      methodPaymentTypes: [],
      officeTypes: [],
      msg: "Espera un momento 🥁 🚀",
      invoice: {
        name: "",
        rfc: "",
        taxRegime: "",
        email: "",
        street: "",
        outdoorNumber: "",
        interiorNumber: "",
        municipality: "",
        state: "",
        country: "",
        zipCode: "",
        colony: "",
        paymentTypeID: "",
        paymentMethod: "",
        branch: "",
        total: "",
        digits: "",
        bank: "",
        numberTicket: "",
        dateTicket: "",
        wayPayment: "",
      },
      files: [],
      showLoader: false,
      showMessage: false,
      dropzoneOptions: {
        url: "http://ape-admin.com/api/v1/invoices",
        thumbnailWidth: 250,
        thumbnailHeight: 280,
        maxFilesize: 6,
        autoProcessQueue: false,
        addRemoveLinks: true,
      },
      OptionsBranch: [
        { id: 1, name: "ESTACIONAMIENTO AGUASCALIENTES RIVERO Y GUTIE" },
        { id: 2, name: "ESTACIONAMIENTO VERACRUZ" },
        { id: 3, name: "ESTACIONAMIENTO NICOLAS ROMERO 1" },
        { id: 4, name: "ESTACIONAMIENTO NICOLAS ROMERO 2" },
        { id: 5, name: "ESTACIONAMIENTO AGUASCALIENTES 5 DE MAYO" },
        { id: 6, name: "ESTACIONAMIENTO TOLUCA LYCESA" },
        { id: 7, name: "ESTACIONAMIENTO MERIDA 3" },
        { id: 8, name: "ESTACIONAMIENTO QUERETARO COLON" },
        { id: 10, name: "ESTACIONAMIENTO LOS REYES LA PAZ" },
        { id: 11, name: "ESTACIONAMIENTO CHIAPAS" },
        { id: 12, name: "ESTACIONAMIENTO SALTILLO" },
        { id: 13, name: "ESTACIONAMIENTO COACALCO" },
        { id: 13, name: "PUERTO AEREO" },
      ],
      OptionsTaxRegime: [
        { id: 1, value: "601 General de Ley Personas Morales" },
        { id: 2, value: "603 Personas Morales con Fines no Lucrativos" },
        {
          id: 3,
          value: "605 Sueldos y Salarios e Ingresos Asimilados a Salarios",
        },
        { id: 4, value: "606 Arrendamiento" },
        { id: 5, value: "607 Régimen de Enajenación o Adquisición de Bienes" },
        { id: 6, value: "608 Demás ingresos" },
        {
          id: 7,
          value:
            "610 Residentes en el Extranjero sin Establecimiento Permanente en México",
        },
        { id: 8, value: "611 Ingresos por Dividendos (socios y accionistas)" },
        {
          id: 9,
          value:
            "612 Personas Físicas con Actividades Empresariales y Profesionales",
        },
        { id: 10, value: "614 Ingresos por intereses" },
        {
          id: 11,
          value: "615 Régimen de los ingresos por obtención de premios",
        },
        { id: 12, value: "616 Sin obligaciones fiscales" },
        {
          id: 13,
          value:
            "620 Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
        },
        { id: 14, value: "621 Incorporación Fiscal" },
        {
          id: 15,
          value: "622 Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
        },
        { id: 16, value: "623 Opcional para Grupos de Sociedades" },
        { id: 17, value: "624 Coordinados" },
        {
          id: 18,
          value:
            "625 Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
        },
        { id: 19, value: "626 Régimen Simplificado de Confianza" },
      ],
    };
  },
  mounted() {
    this.fetchPaymentTypes();
    this.fetchMethodPaymentTypes();
    this.fetchOfficeTypes();
  },
  methods: {
    fetchPaymentTypes() {
      const url = "http://parking-invoices-back-office.test/api/payment-types";
      axios.get(url).then((response) => {
        this.paymentTypes = response.data;
      });
    },
    handleValidationFailed() {
      this.validationError = "Debe subir al menos un archivo.";
    },
    sendState(state) {
      this.$emit("sendState", state);
    },
    fetchMethodPaymentTypes() {
      const url =
        "http://parking-invoices-back-office.test/api/method-payments";
      axios.get(url).then((response) => {
        this.methodPaymentTypes = response.data;
      });
    },
    fetchOfficeTypes() {
      const url = "http://parking-invoices-back-office.test/api/offices-types";
      axios.get(url).then((response) => {
        this.officeTypes = response.data;
      });
    },
    submit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const fileList = this.$refs.myVueDropzone.getAcceptedFiles();
          const formData = new FormData();
          fileList.forEach((file) => {
            formData.append("files[]", file, file);
          });

          if (fileList.length === 0) {
            this.handleValidationFailed();
            return;
          }
          this.showLoader = true;

          const headers = { "Content-Type": "multipart/form-data" };
          const url = "https://admin-ape.com/api/v1/invoices";
          //const url = "http://ape-plazas.test/api/v1/invoices";
          axios
            .post(url, formData, { params: this.invoice }, { headers })
            .then((res) => {
              if (res.data.message === "ok") {
                this.sendState(true);
                this.showLoader = false;
                this.$validator.reset();
                for (const property in this.invoice) {
                  this.invoice[property] = null;
                }
                this.$validator.reset();
                this.$refs.myVueDropzone.removeAllFiles();
                this.validationError = null;
              }
            })
            .catch(() => {
              this.showLoader = false;
            });
        }
      });
    },
  },
};
</script>
