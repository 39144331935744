import Vue from "vue";
import veeValidate, { Validator } from "vee-validate";
import validatorEs from "vee-validate/dist/locale/es";
Vue.use(veeValidate, {
  validity: true,
  fieldsBagName: "veeFields",
  aria: false,
  classes: true,
  classNames: {
    validTooltip: "valid-tooltip",
    invalidTooltip: "invalid-tooltip",
    valid: "is-valid",
    invalid: "is-invalid",
    errorBagName: "errors", // change if property conflicts
    events: "input|blur",
    inject: true,
  },
});
Validator.localize("es", validatorEs);
