<template>
  <div>
    <footer class="footer">
      <div class="footer__grid container">
        <p class="footer__copyright">Todos los derechos reservados</p>
        <div class="footer__aviso-privacidad">
          <button @click="show">Aviso de Privacidad</button>
        </div>
      </div>
    </footer>
    <modal name="my-first-modal" :width="900" :height="700">
      <h3 class="sow-headline" style="text-align: center; color: #dd3333">
        AVISO DE PRIVACIDAD
      </h3>
      <p>Estimado Usuario:</p>
      <p>
        En cumplimiento con la Ley Federal de Protección de Datos Personales en
        Posesión de los Particulares y con el fin de asegurar la protección y
        privacidad de los datos personales, así como regular el acceso,
        rectificación, cancelación, y oposición del manejo de los mismos PASAJE
        DE ELECTRONICA EL SALVADOR S.A. de C.V. Y PLAZA LÓPEZ COTILLA S.A. de
        C.V. establece lo siguiente:
      </p>
      <p>
        PASAJE DE ELECTRONICA EL SALVADOR S.A. de C.V. Y PLAZA LÓPEZ COTILLA
        S.A. de C.V., es el responsable de recabar sus datos personales
        señalando como domicilio el ubicado en Bosque de Duraznos 61-6C, Colonia
        Bosques de las Lomas, Delegación Miguel Hidalgo, México Distrito
        Federal. C.P. 11700. nuestros datos de contacto se encuentran en nuestra
        página de internet www.apeplazas.com
      </p>
      <p>1. Descripción del Aviso</p>
      <p>
        Los términos que se utilizan en el presente aviso de privacidad (en lo
        sucesivo referido como “Aviso”) tendrán la definición que a los mismos
        le asigna la Ley Federal de Protección de Datos Personales en posesión
        de los Particulares, publicada en el Diario Oficial de la Federación el
        5 de julio de 2010 (en lo sucesivo referida como la “Ley”). En su art. 3
        Fracc. I
      </p>
      <p>
        Este Aviso especifica el tipo de datos que PASAJE DE ELECTRONICA EL
        SALVADOR S.A. de C.V. Y PLAZA LOPEZ COTILLA S.A. de C.V. recolecta, los
        fines para los cuales lleva a cabo el tratamiento de los mismos, así
        como los derechos con que cuenta el Usuario al respecto.
      </p>
      <p>
        Datos personales que recaba PASAJE DE ELECTRONICA EL SALVADOR S.A. de
        C.V. Y PLAZA LOPEZ COTILLA S.A. de C.V.
      </p>
      <p>
        2.Los datos personales que puede recabar PASAJE DE ELECTRÓNICA EL
        SALVADOR S.A. de C.V. Y PLAZA LOPEZ COTILLA S.A. de C.V. de sus Usuarios
        incluyen (i) nombres, apellidos, domicilios, teléfonos, correos
        electrónicos, claves del Registro Federal de Contribuyentes, clave Única
        de Registro de población; (ii) toda aquella información que el Usuario
        ha proporcionado y/o le proporcione a lo largo de la relación
        contractual o comercial que establezcan; (iii) aquellos datos propios y
        relacionados con los servicios que presta en el cumplimiento de sus
        obligaciones; (iv) cualesquiera otros datos que se recaben de tiempo en
        tiempo con motivo de la prestación de los servicios.
      </p>
      <p>
        Toda información personal que identifica al Usuario, revelada de manera
        directa o por cualquier otro medio de contacto, podrá ser recopilada y
        tratada por PASAJE DE ELECTRONICA EL SALVADOR, S.A. de C.V.
      </p>
      <p>
        En caso de que el Usuario proporcione sus datos personales a través de
        medios electrónicos, incluyendo el sitio web de PASAJE DE ELECTRONICA EL
        SALVADOR, S.A. de C.V., entiende acepta y reconoce que:
      </p>
      <p>
        a) El sitio web de PASAJE DE ELECTRONICA EL SALVADOR, S.A. de C.V. puede
        incluir enlaces a sitios web de terceros, que de accederse, ocasionará
        que se abandone el sitio web de PASAJE DE ELECTRONICA EL SALVADOR, S.A.
        de C.V. por lo cual PASAJE DE ELECTRONICA EL SALVADOR, S.A. de C.V. no
        asume ninguna responsabilidad en relación con esos sitios web de
        terceros.
      </p>
      <p>
        b) El sitio web de PASAJE DE ELECTRONICA EL SALVADOR, S.A. de C.V. puede
        incluir enlaces que administran redes sociales, en cuyo caso el Usuario
        acepta que al proporcionar cualquier tipo de información o datos en
        dichos sitios, ocasionará que la misma pueda ser leída, vista, accedida,
        retransmitida y tratada por cualquier persona, y por lo tanto libera de
        cualquier responsabilidad a PASAJE DE ELECTRONICA EL SALVADOR, S.A. de
        C.V.
      </p>
      <p>
        c) Es posible que los sistemas de PASAJE DE ELECTRONICA EL SALVADOR,
        S.A. de C.V. recopilen datos del Usuario tales como tipo de navegador,
        sistema operativo, páginas de internet visitadas, dirección IP, etc., a
        través de “cookies” o “web beacons”, entre otros.
      </p>
      <p>
        La recolección de datos que realiza PASAJE DE ELECTRONICA EL SALVADOR,
        S.A. de C.V. es de buena fe y por tal motivo presume que los mismos son
        verídicos, correctos, completos e identifican al Usuario que los
        suministra y/o provee, por lo que es responsabilidad del Usuario que los
        datos que este le proporcione a PASAJE DE ELECTRONICA EL SALVADOR, S.A.
        de C.V. cumplan con tales características y se actualicen en la medida
        que se requiera. De igual forma, PASAJE DE ELECTRONICA EL SALVADOR, S.A.
        de C.V. se reserva el derecho de ejercer las acciones que considere
        pertinentes en caso de falsedad de datos.
      </p>
      <p>
        En el caso de los Datos sensibles que el Usuario no ha proporcionado y
        en ningún caso proporcionará a PASAJE DE ELECTRONICA EL SALVADOR, S.A.
        de C.V. los datos personales sensibles, es decir, aquellos datos
        personales que afecten a la esfera más íntima de su titular, o cuya
        utilización indebida pueda dar origen a discriminación o conlleve un
        riesgo grave para este. En particular, se consideran sensibles aquellos
        que puedan revelar aspectos como origen racial o étnico, estado de salud
        presente y futuro, información genética, creencias, religiosas,
        filosóficas y morales, afiliación sindical, opiniones, políticas,
        preferencia sexual, o conlleve un riesgo grave para éste. En particular,
        el Usuario se obliga a no proporcionar a PASAJE DE ELECTRONICA EL
        SALVADOR, S.A. de C.V. ningún Dato relativo a origen racial o étnico,
        estado de salud presente y futuro, información genética, creencias
        religiosas, filosóficas y morales, afiliación sindical, opiniones
        políticas o preferencia sexual. Tal y como lo señala el art. 3 fracc. VI
        de la Ley Federal de Protección de Datos Personales en Posesión de los
        Particulares.
      </p>
      <p>3. Finalidad con que se recaban los datos personales.</p>
      <p>
        Los datos personales podrán ser tratados y utilizados por PASAJE DE
        ELECTRONICA EL SALVADOR, S.A de C.V. y/o subsidiarias y/o afiliadas y/o
        terceros, nacionales y extranjeros para llevar a cabo alguna o todas las
        actividades relacionadas con el cumplimiento de las obligaciones que
        deriven de la relación contractual y/o comercial que se originen y/o
        deriven de la prestación de servicios, con el propósito de informar al
        Usuario sobre cambios relacionados con lo contratado, así como para
        cualquier otra actividad tendiente a promover, mantener, mejorar y
        evaluar los servicios, contactarlo para pedir opinión sobre marcas y
        productos, enviarle información sobre promociones, experiencias
        personalizadas, campañas y nuevos productos de la marca, para poder
        participar en cursos y entregar premios, y hacer perfiles con los gustos
        y preferencias del usuario.
      </p>
      <p>4. Transferencia de datos personales.</p>
      <p>
        PASAJE DE ELECTRONICA EL SALVADOR, S.A. de C.V. podrá transferir los
        datos personales que haya recibido y/o recolectado y/o llegue a recibir
        y/o recolectar de sus Usuarios a sus subsidiarias y/o afiliadas y/o
        terceros, ya sean nacionales y/o extranjeros, y/o cualquier autoridad
        competente que así lo solicite para llevar a cabo las finalidades
        descritas en el punto 3 del presente aviso; La transferencia de los
        datos personales del Usuario se encuentra limitada a aquellos actos,
        hechos y/o procedimientos que PASAJE DE ELECTRONICA EL SALVADOR, S.A. de
        C.V. requiera implementar a efecto de estar en posibilidad de cumplir
        con sus obligaciones, regulatorias y/o comerciales en el curso ordinario
        de sus operaciones. Si el Usuario no manifiesta su oposición para que
        sus datos personales sean transferidos, se entenderá que ha otorgado su
        consentimiento para ello, tal y como lo señala el art. 8 de la Ley
        Federal de Protección de Datos Personales en Posesión de los
        Particulares.
      </p>
      <p>
        5. Medios para ejercer los derechos de acceso, rectificación,
        cancelación u oposición.
      </p>
      <p>
        PASAJE DE ELECTRONICA EL SALVADOR, S.A. de C.V. cuenta con los recursos
        técnicos, materiales y humanos necesarios para asegurar que los datos
        personales del Usuario serán tratados con estricto apego a la Ley. En
        cualquier momento, el Usuario tendrá el derecho a solicitar a PASAJE DE
        ELECTRONICA EL SALVADOR, S.A. de C.V. el ejercicio que le confiere la
        Ley, mediante el envió de la solicitud correspondiente por escrito al
        siguiente correo electrónico modificaciones@apeplazas.com
      </p>
      <p>
        La solicitud del Usuario para poder ejercer sus derechos de acceso,
        rectificación, cancelación u oposición, deberá contener como mínimo: (i)
        Nombre completo del Usuario, (ii) Número de Teléfono celular, (iii)
        descripción detallada del objeto de la solicitud, y (iv) razones que
        motivan su solicitud.
      </p>
      <p>
        Nuestra respuesta a su solicitud será enviada dentro del plazo permitido
        por la Ley al correo electrónico del remitente de la misma. El Usuario
        reconoce y acepta que una de las finalidades del tratamiento de sus
        datos personales es cumplir con sus obligaciones contractuales y/o
        comerciales que mantiene y/o mantendrá con PASAJE DE ELECTRONICA EL
        SALVADOR, S.A de C.V., por lo que no podrá cancelar y/u oponerse al
        tratamiento de datos personales que puedan y/o pudieran afectar y/o
        restringir el cumplimiento de las mismas.
      </p>
      <p>6. Modificaciones al aviso de privacidad.</p>
      <p>
        PASAJE DE ELECTRONICA EL SALVADOR, S.A. de C.V. expresamente se reserva
        el derecho, bajo su exclusiva discreción, de cambiar, modificar, agregar
        o eliminar partes del presente Aviso en cualquier momento. En tal caso,
        PASAJE DE ELECTRONICA, S.A. de C.V. informará las modificaciones que se
        le practiquen al Aviso vía correo electrónico. En la medida que el
        Usuario no solicite, en los términos antes mencionados, la cancelación
        y/u oposición de sus datos personales, implica que ha aceptado y
        consentido tales cambios y/o modificaciones.
      </p>
      <p>
        Al proporcionarle sus datos personales a PASAJE DE ELECTRONICA EL
        SALVADOR, S.A. de C.V., el Usuario expresamente reconoce y acepta el
        presente Aviso, según el mismo pueda ser modificado o ajustado, y otorga
        su consentimiento para que PASAJE DE ELECTRONICA EL SALVADOR, S.A. de
        C.V. proceda con el procesamiento de sus datos personales de la forma
        que se explica en el presente Aviso. Si el Usuario no acepta este Aviso
        podrá ejercer los derechos que le confiere la Ley.
      </p>
    </modal>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  methods: {
    show() {
      this.$modal.show("my-first-modal");
    },
  },
};
</script>

<style>
.vm--modal {
  overflow: auto !important;
}
</style>
