<template>
  <div id="app">
    <HeaderComponent />
    <InformationComponent />
    <FormComponent @sendState="handleEstado" />
    <FooterComponent />
    <div
      class="modal"
      id="datosEnviadosModal"
      tabindex="-1"
      role="dialog"
      :class="{ show: showModal }"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Datos enviados</h5>
            <button type="button" class="close" @click="showModal = false">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Tus datos han sido enviados exitosamente.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="showModal = false">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "./components/HeaderComponent.vue";
import InformationComponent from "./components/InformationComponent.vue";
import FormComponent from "./components/FormComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";


export default {
  name: "App",
  components: {
    HeaderComponent,
    InformationComponent,
    FormComponent,
    FooterComponent,
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    handleEstado(state) {
      this.showModal = state;
    }
  },
};
</script>

<style>
/* Estilos para el modal */
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
  background: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
}

.modal.show {
  display: flex;
  align-items: center; /* Centrado vertical */
  justify-content: center; /* Centrado horizontal */
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #e9ecef;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #e9ecef;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  background-color: #5a6268;
  border-color: #545b62;
}
</style>
